@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap);
body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

pre {
    white-space: pre-wrap;
}

a:link,
a:visited {
    /*text-decoration: none;*/
    color: inherit;
}

*:target {
    scroll-margin-top: 185px;
}

.hidden {
    display: none !important;
}

.print-only,
.print-only * {
    display: none !important;
}

.visible-print-only,
.visible-print-only * {
    visibility: hidden !important;
}

h4 p,
h5 p,
h6 p,
span p:only-of-type {
    display: inline;
}

@media print {
    * {
        -webkit-print-color-adjust: exact;
    }

    .no-print,
    .no-print * {
        display: none !important;
    }

    .print-only,
    .print-only * {
        display: inherit !important;
    }

    .visible-print-only,
    .visible-print-only * {
        visibility: visible !important;
    }

    .print-document-page {
        box-shadow: none !important;
        padding: 0 !important;
        margin: 0 !important;
        background: #fff !important;
    }

    .print-document-container {
        padding: 0 !important;
        margin: 0 !important;
        background: #fff !important;
    }

    .MuiTooltip-tooltip {
        display: none !important;
    }

    table {
        table-layout: fixed !important;
        width: 100% !important;
        max-width: 100vw !important;
    }

    .page-break {
        page-break-before: always;
    }
}

.renvoi-mode-normal .is-renvoi del,
.renvoi-mode-normal.is-renvoi del,
.renvoi-mode-normal .is-full-renvoi,
.renvoi-mode-normal.is-full-renvoi,
.renvoi-mode-normal .is-full-renvoi del,
.renvoi-mode-normal.is-full-renvoi del {
    text-decoration: none;
}

.renvoi-mode-removed .is-renvoi del,
.renvoi-mode-removed.is-renvoi del,
.renvoi-mode-removed .is-full-renvoi,
.renvoi-mode-removed.is-full-renvoi {
    display: none;
}

/* don't ask me, ask chrome developers https://bugzilla.mozilla.org/show_bug.cgi?id=1344545 */
.renvoi-mode-strikethrough.is-full-renvoi,
.renvoi-mode-strikethrough .is-full-renvoi,
.renvoi-mode-strikethrough .is-full-renvoi h1,
.renvoi-mode-strikethrough .is-full-renvoi h2,
.renvoi-mode-strikethrough .is-full-renvoi h3,
.renvoi-mode-strikethrough .is-full-renvoi h4,
.renvoi-mode-strikethrough .is-full-renvoi h5,
.renvoi-mode-strikethrough .is-full-renvoi h6 {
    text-decoration: line-through;
}



.block-list {
    padding-top: 8px;
    padding-bottom: 8px;
}

ul.block-list div.block-item, ol.block-list div.block-item {
    padding-left: 10px
}

ul.block-list div.non-list-item, ol.block-list div.non-list-item {
    margin: 8px 0 8px -15px;
}

